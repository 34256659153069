import React from 'react';
import BaseLayoutSetup from '../layouts/BaseLayoutSetup';

const SuccessPage = () => (
  <BaseLayoutSetup>
    <h1>Submitted successfully!</h1>
    <p>
      Form received successfully! I&apos;ll respond to the email you provided!
    </p>
  </BaseLayoutSetup>
);

export default SuccessPage;
